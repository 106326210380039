// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opus-js": () => import("./../../src/pages/opus.js" /* webpackChunkName: "component---src-pages-opus-js" */),
  "component---src-pages-pickles-js": () => import("./../../src/pages/pickles.js" /* webpackChunkName: "component---src-pages-pickles-js" */),
  "component---src-pages-weatherd-js": () => import("./../../src/pages/weatherd.js" /* webpackChunkName: "component---src-pages-weatherd-js" */),
  "component---src-pages-work-js": () => import("./../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

