import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#336666',
      dark: '#1f3536',
      contrastText: '#efe7e4',
    },
    secondary: {
      main: '#bf563d',
      dark: '#bc442c',
      contrastText: '#efe7e4',
    },
    error: {
      main: '#990000',
      contrastText: '#efe7e4',
    },
    warning: {
      main: '#990000',
    },
    background: {
      default: '#efe7e4',
    },
    text: {
      primary: '#1f3536',
    },
  },
  typography: {
    body1: {
      fontWeight: 300,
      fontSize: '1.2rem',
    },
    fontFamily: [
      'Roboto',
      '"Roboto Condensed"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

theme = responsiveFontSizes(theme);

export default theme;
