import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export default function RootLayout({ children }) {
  const data = useStaticQuery(graphql`
    query {
      site: file(relativePath: { eq: "site.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta name="title" property="og:title" content="My Portfolio Site" />
        <meta
          name="image"
          property="og:image"
          content="https://jennmez.com/static/2882de7e429d99547e72a434363526d5/497c6/site.png"
        />
        <meta
          name="description"
          property="og:description"
          content="I design and build neat web applications."
        />
        <meta name="author" content="Jenn Misewicz" />

        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
    </>
  );
}
